import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { first } from 'rxjs/operators';
import { Member } from 'src/app/models/member-list';
import { Modal } from 'src/app/models/modals/modal';
import { ModalImageType } from 'src/app/models/modals/modal-base';
import { ModalPermissions } from 'src/app/models/modals/modal-permissions';
import { ReaderPermission, RegisterMemberPermission } from 'src/app/models/requests/register-member-permission';
import { LoadingService } from 'src/app/services/common/loading.service';
import { ModalService } from 'src/app/services/common/modal.service';
import { MembersService } from 'src/app/services/members/members.service';

@Component({
  selector: 'app-lkwd-member-permissions',
  templateUrl: './lkwd-member-permissions.component.html',
  styleUrls: ['./lkwd-member-permissions.component.css']
})
export class LkwdMemberPermissionsComponent implements OnInit {

  @Input() currentModal: ModalPermissions;
  @Output() closeModal:EventEmitter<any> = new EventEmitter();

  private permissionsDicitonary:any = {};
  public members:Member[];
  private memberId:number;
  public memberName:string = '';

  constructor(private memberService:MembersService, 
    private loadingService:LoadingService,
    private lkwdModalService: ModalService) { }

  ngOnInit(): void {
    this.memberService.getMemberList(this.currentModal.userId)
    .pipe(first())
    .subscribe({
      next: (_memberList) => {
        this.memberId = _memberList.memberId;
        this.memberName = _memberList.memberName;
        this.members = _memberList.members;
      },
      error: member_error => {
      }
    });      
  }

  onAllowReader(value:boolean, reader:number) {
    this.permissionsDicitonary[reader] = value;    
  }

  onSavePermissions() {
    let permissionsSet:ReaderPermission[] = [];
    for (let [reader, value] of Object.entries(this.permissionsDicitonary)) {
      permissionsSet.push(new ReaderPermission(parseInt(reader), value.toString() == 'true'));
    }
    this.loadingService.setLoading();

    this.memberService.registerPermission(new RegisterMemberPermission(permissionsSet, this.memberId))
    .subscribe({
      next: (_resp) => {        
        this.loadingService.setLoading(false);
        this.onCloseModal();

        this.lkwdModalService.showModal(new Modal (
          'Permissões salvas com sucesso!', '',
          ModalImageType.success)    
        );
        
      },
      error: error => {
        this.loadingService.setLoading(false);
        this.onCloseModal();
      }
    });
  }

  onCloseModal() {
    this.closeModal.emit();  
  }
}
