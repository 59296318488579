import { Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { ReportType } from 'src/app/models/report-type';
import { first } from 'rxjs/operators';
import { ReportService } from 'src/app/services/reports/report.service';
import { Report } from 'src/app/models/report';
import { ReportDownload } from 'src/app/models/report-download';
import { saveAs } from 'file-saver';
import { ModalService } from 'src/app/services/common/modal.service';
import { ModalButton, ModalImageType } from 'src/app/models/modals/modal-base';
import { Modal } from 'src/app/models/modals/modal';
import { LoadingService } from 'src/app/services/common/loading.service';
import { DateRange } from '@uiowa/date-range-picker';
import { LkwdTuple } from 'src/app/models/common/lkwd-tuple';
import { LkwdScreen } from 'src/app/models/common/lkwd-screen';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-lkwd-reports-table',
  templateUrl: './lkwd-reports-table.component.html'
})
export class LkwdReportsTableComponent implements OnInit {

  public screen:LkwdScreen = LkwdScreen.Reports;  
  isEmpty:boolean = undefined;

  @Input() clientId = null;
  @Input() needTablerefresh = false;

  canEdit = true;

  //Data
  public dateRange: DateRange = new DateRange();
  onDateRangeChange(range: DateRange) {
    this.paginate(1);
  }

  //Enviado por
  public sentByModel:string;
  public sentByFilterString:String[];
  public sentByFilter:LkwdTuple[];
  public sentById:number;
  onSenderChange(model:string) {
    this.sentByModel = model;
    if(this.isModelEmpty()) {
      this.clearModel();
      this.paginate(1);
      return;
    }

    var found = this.sentByFilter.find(x => x.name == model);
    if(found != undefined) {
      this.sentById = found.id;      
      this.paginate(1);
    }
  }

  //Cliente
  public sentToModel:string;
  public sentToFilterString:String[];
  public sentToFilter:LkwdTuple[];
  public searchedClientId:number;
  onClientChange(model:string) {
    this.sentToModel = model;
    if(this.isModelEmpty()) {
      this.clearModel();
      this.paginate(1);
      return;
    }

    var found = this.sentToFilter.find(x => x.name == model);

    if(found != undefined) {
      this.searchedClientId = found.id;
      this.paginate(1);
    }
  }
  
  //Tipo de relatório
  public reportTypeModel: string;
  public reportTypeFilter:string[] = ['Book', 'Lâmina', 'Offshore', 'Onshore'];
  public searchedReportType:number;
  onReportTypeChange(model:string) {
    this.reportTypeModel = model;
    if(this.isModelEmpty()) {
      this.clearModel();
      this.paginate(1);
      return;
    }

    this.searchedReportType = 
    this.reportTypes.find(x => x.name.toLowerCase().normalize("NFD") == model?.toLowerCase().normalize("NFD"))?.id;

    if(this.searchedReportType != undefined) {

      this.paginate(1);
    }
  }

  isModelEmpty() {
    return this.sentByModel == '' &&
       this.sentToModel == '' &&
       this.dateRange.start == null &&
       this.dateRange.end == null &&
       this.reportTypeModel == '';
  }


  public collectionSize;
  public pageSize = 8;
  public headers = ['Enviado para', 'Enviado por', 'Tipo de relatório', 'Data de envio', 'Arquivo', ''];
  public dataTable = [];
  currentPage = 1;
  // hidePagination = false;
  public hideTable = false;

  reportType:ReportType;
  reportTypes: LkwdTuple[] = [
    {id: ReportType.Lamina, name: 'Lâmina'},
    {id: ReportType.Onshore, name: 'Onshore'},
    {id: ReportType.Offshore, name: 'Offshore'},
    {id: ReportType.Book, name: 'Book'},
  ];

  onClearSearch() {
    this.clearModel();
    this.paginate(1);
  }

  private clearModel() {    
    this.reportTypeModel = '';
    this.sentByModel = '';
    this.sentToModel = '';
    this.searchedReportType = null;
    this.searchedClientId = this.clientId;
    this.sentById = null;
    this.dateRange = new DateRange();    
    this.paginate(1);
  }

  public onPageChanged (page: number) { this.paginate(page); }

  constructor(
    private reportService: ReportService, 
    private modalService: ModalService,
    private loadingService: LoadingService,
    private accountService: AccountService) { }

  private FormatObjectForTable(clients: Report[]) {
    return clients.map(x => {
    
    let _return  = [      
      { value: x.createdBy, type: 'text'},
      { value: this.reportTypes.find(t => t.id == x.type).name, type: 'text'},
      { value: x.reportTimedString, type: 'text'},
      { value: x.reportName, type: 'reportFile', Id: x.id}
    ];

    if(this.clientId == null) {
      _return.unshift({ value: x.sentTo, type: 'text'});
      if(this.canEdit) {
        _return.push({ value: x.reportName, type: 'actionImage', Id: x.id});
      }      
    }

    return _return;
    });
  }


  private paginate (page: number) {
    this.currentPage = page;
    this.hideTable = true;
    this.loadingService.setLoading();

    this.reportService.paginated(page,this.pageSize, this.searchedClientId, this.searchedReportType, this.dateRange?.start, this.dateRange?.end, null, null, this.sentById)
    .pipe(first())
    .subscribe({
      next: (_reports) => {
        this.dataTable = this.FormatObjectForTable(_reports.reports);

        this.collectionSize = _reports.collectionSize;
        this.currentPage = _reports.pageNumber;

        this.hideTable = false;
        this.loadingService.setLoading(false);

        if(this.isEmpty == undefined) {
          this.isEmpty = _reports.reports.length == 0;
        }
      },
      error: error => {
        this.hideTable = false;
        this.loadingService.setLoading(false);
      }
    })
  }

  ngOnInit(): void { 
    if(this.accountService.userValue.permissions != null) {
      this.canEdit = this.accountService.userValue.permissions.reportsEdit;
    }

    if(this.clientId != null) {     
      this.searchedClientId = this.clientId; 
      this.headers.shift();
      this.headers.pop();
    } 
    else if(!this.canEdit) {
      this.headers.pop();
    }

    this.paginate(1);
    this.clearModel();

    this.reportService.getFilterOptions(true, true)
    .pipe(first())
    .subscribe({
      next: (_options) => {
        this.sentToFilter = _options.clients;
        this.sentToFilterString = this.sentToFilter.map(x => x.name);

        this.sentByFilter = _options.reportSenders;
        this.sentByFilterString = this.sentByFilter.map(x => x.name);
      },
      error: error => {
      }
    });
  }

  onActionImage(reportId:number) {
    this.modalService.showModal(new Modal (
      'Tem certeza que você deseja excluir este relatório?',
      '',
      ModalImageType.error,
      new ModalButton({text: 'Sim', link: 'relatorios/remover/' + reportId })    
    ));
  }

  onDownloadFile(report:ReportDownload) {
    this.loadingService.setLoading();
    this.reportService.getDownloadSignedUrl(report.id) 
    .subscribe(
      response => 
      {
        this.reportService.donwload(response.signedUrl)
        .subscribe(
          response => 
          {
            var blob = new Blob([response], { type: "application/pdf"});
            saveAs(blob, report.fileName);
          },
          error => {
            console.log(error);        
          },
          () => this.loadingService.setLoading(false)
        );
      },
      error => {  
        console.log(error);
      },
      () => this.loadingService.setLoading(false)
    );
  }

  //Reload page on add new report
  ngOnChanges(changes: SimpleChanges) {
    if(this.clientId != null) {     
      this.searchedClientId = this.clientId;
    } 
    if(!changes?.needTablerefresh?.isFirstChange() && changes.needTablerefresh) {
      this.paginate(1);
    }
  }
}
