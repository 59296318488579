import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map } from 'rxjs/operators';
import { Client } from 'src/app/models/client';
import { Modal } from 'src/app/models/modals/modal';
import { ModalButton, ModalImageType } from 'src/app/models/modals/modal-base';
import { ModalCreateFamily } from 'src/app/models/modals/modal-create-family';
import { UserRole } from 'src/app/models/user';
import { UserBase } from 'src/app/models/user-base';
import { LoadingService } from 'src/app/services/common/loading.service';
import { ModalService } from 'src/app/services/common/modal.service';
import { FamilyService } from 'src/app/services/family/family.service';
import { UsersService } from 'src/app/services/users/users.service';

export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-lkwd-create-family',
  templateUrl: './lkwd-create-family.component.html',
  styleUrls: ['./lkwd-create-family.component.css']
})
export class LkwdCreateFamilyComponent implements OnInit {

  @Input() currentModal: ModalCreateFamily;
  @Output() closeModal:EventEmitter<any> = new EventEmitter();
  public showAddFamily = false;
  
  constructor(
    private userService: UsersService, 
    private familyService: FamilyService,
    private loadingService: LoadingService,
    private lkwdModalService: ModalService) { }

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  displayFamilyNameError = false;
  displayClientError = false;

  selectedClients: UserBase[] = [];
  public familyName:string;

  clientFilter: Client[] = [];

  public clientModel: any;
  private FilterTypeText(list:string[], typed:string) {
    return list.filter(v => v.toLowerCase()
    .indexOf(typed.toLowerCase()) > -1)
    .slice(0, 10);
  }

  searchClient = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term.length < 2 ? 
      [] : this.FilterTypeText(this.clientFilter.map(x => x.name), term))
  )

  // private onFilterChange(){
  //   if(this.clientModel == '' && this.cpfModel == '' && this.familyModel == '') {
  //     this.paginate(this.currentPage);
  //   } else {
  //     this.FilterClients();
  //   }
  // }

  onClientFilterChange(name:string) {
    let filterCopy = [...this.clientFilter].map(i => ({ ...i}));
    let newClient = filterCopy.find(x => x.name == name);
    if(newClient != undefined)
    {  
      if(newClient.name.length > 13) {
        newClient.name = newClient.name.substr(0,12) + '...';
      }        

      this.selectedClients.push(newClient);
      this.clientModel = null;
    }
    else 
    {
      this.clientModel = name;
    }    
    // this.onFilterChange();
  }

  // onMemberFilterChange(name:string) {
  //   let filterCopy = [...this.clientFilter].map(i => ({ ...i}));
  //   let newClient = filterCopy.find(x => x.name == name);
  //   if(newClient != undefined)
  //   {  
  //     if(newClient.name.length > 13) {
  //       newClient.name = newClient.name.substr(0,12) + '...';
  //     }        

  //     this.selectedClients.push(newClient);
  //     this.clientModel = null;
  //   }
  //   else 
  //   {
  //     this.clientModel = name;
  //   }    
  //   // this.onFilterChange();
  // }

  ngOnInit(): void {
    this.showAddFamily = this.currentModal.addFamily;
    this.userService.get(UserRole.Client, false)
    .pipe(first())
    .subscribe({
      next: (_users) => {
        this.clientFilter = _users;
      },
      error: users_error => {
      }
    }); 
  }

  onRemoveUser(client: Client): void {
    const index = this.selectedClients.indexOf(client);

    if (index >= 0) {
      this.selectedClients.splice(index, 1);
    }
  }

  onCreateFamily() {
    if(this.familyName == undefined || this.familyName == '') {
      this.displayFamilyNameError = true;
      return;
    }
    this.loadingService.setLoading();
    this.familyService.postFamilies(this.selectedClients.map(x => x.id), undefined, this.familyName)
    .pipe(first())
    .subscribe({
      next: (_familyId) => {
        this.loadingService.setLoading(false);
        this.onCloseModal();
        this.lkwdModalService.closeModal(this.currentModal);
        this.lkwdModalService.showModal(new Modal (
          'Grupo familiar criado com sucesso!','',
          ModalImageType.success,
          new ModalButton({text: 'Visualizar grupo familiar', link: 'grupos-familiares/membros/' + _familyId })    
        ));
      },
      error: users_error => {
        this.loadingService.setLoading(false);
      }
    });  
  }

  onAddMember() {
    if(this.clientModel == '' || this.clientModel == undefined) {
      this.displayClientError = true;
    }
    let newMember = this.clientFilter.find(x => x.name == this.clientModel);
    this.loadingService.setLoading();
    this.familyService.postFamilies([newMember.id], this.currentModal.familyId)
    .pipe(first())
    .subscribe({
      next: (_familyId) => {
        this.loadingService.setLoading(false);
        this.onCloseModal();
        this.lkwdModalService.closeModal(this.currentModal);
        this.lkwdModalService.showModal(new Modal (
          'Membro familiar adicionado com sucesso!','',
          ModalImageType.success    
        ));
      },
      error: users_error => {
        this.loadingService.setLoading(false);
      }
    });
  }

  onCloseModal() {
    this.closeModal.emit();
  }

  onKeyUp() {
  }
}
