import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Client } from '../models/client';
import { Modal } from '../models/modals/modal';
import { ModalImageType } from '../models/modals/modal-base';
import { UserBase } from '../models/user-base';
import { LoadingService } from '../services/common/loading.service';
import { ModalService } from '../services/common/modal.service';
import { UsersService } from '../services/users/users.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {

  constructor(private userService:UsersService, 
    private loadingService:LoadingService,
    private modalService:ModalService) { }

  isEdit = false;
  hidePassword = true;
  model:UserBase;
  public fullPhone;

  ngOnInit(): void {
    this.model = new Client();
    this.loadingService.setLoading();
    this.userService.myAccount()
    .pipe(first())
    .subscribe({
      next: (_user) => {
        this.model = _user;
        if(this.model.phoneDDD != undefined && this.model.phoneNumber != undefined) {
          this.fullPhone = this.model.phoneDDD + this.model.phoneNumber;
        }        
        this.loadingService.setLoading(false);
      },
      error: user_error => {
        this.loadingService.setLoading(false);
      }
    })
  }

  onEditProfile() { this.isEdit = !this.isEdit; }

  onUpdateUser(currentPassword, newPassword) {
    if(true){
      this.model.phoneDDD = this.fullPhone.substr(0,2).replace(/\s+/, "");
      this.model.phoneNumber = this.fullPhone.substr(2,this.fullPhone.length-1).replace(/\s+/, "");
      this.loadingService.setLoading();          
      this.userService.update(this.model, currentPassword, newPassword)
      .pipe(first())
      .subscribe({
        next: (clientId) => {
          this.loadingService.setLoading(false);            
          this.modalService.showModal(new Modal (
            'Informações salvas!',
            'Suas informações foram salvas com sucesso!', 
            ModalImageType.success));
        },
        error: error => {
          this.loadingService.setLoading(false);
        }
      });
    }
  }

}
