import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { AnalyticsService } from '../services/analytics/analytics.service'
import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  constructor(private analyticsService: AnalyticsService) { }

  public loadingConfig = { backdropBorderRadius: '3px', primaryColour: '#00AF97', secondaryColour: '#00AF97', animationType: 'circleSwish'};
  
  public leftChartData = null;  
  public leftChartloading = false;
  
  public rightChartData = null;
  public rightChartloading = false;

  public firstChartOptions = ['7 dias', '15 dias', '30 dias'];

  ngOnInit(): void {
    this.getChartData(true, true, 0);
  }

  private getChartData(leftChart:boolean, rightChart:boolean, range:number) {
    if(leftChart){
      this.leftChartloading = true;
    }
    if(rightChart){
      this.rightChartloading = true;
    }
    this.analyticsService.getAnalytics(range)
    .pipe(first())
    .subscribe({
      next: (_analytics) => {
        if(leftChart) {
          this.leftChartData = [_analytics.tablet, _analytics.mobile, _analytics.web];
          this.leftChartloading = false;
        }
        if(rightChart) {
          this.rightChartData = _analytics.accessHistory;
          this.rightChartloading = false;
        }
      },
      error: error => {
        if(leftChart) {
          this.leftChartloading = false;
        }
        if(rightChart) {
          this.rightChartloading = false;
        }
      }
    })
  }

  exportData(rightChart: boolean) {
    var csvLeftChartData = [];
    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'Quantidade de acessos',
      useTextFile: false,
      useBom: true
    };

    if(rightChart) {
      csvLeftChartData = [...this.rightChartData];
      options['headers'] = ['Data', 'Acessos'];
    } else {
      var leftData = [...this.leftChartData];
      csvLeftChartData = [{ leftData }];
      options['headers'] = ['tablet', 'Mobile', 'Web'];
    }     
    const csvExporter = new ExportToCsv(options);
     
    csvExporter.generateCsv(csvLeftChartData);
  }

  public onChangeDataRangeLeftChart(value: string) {
    let index = this.firstChartOptions.findIndex(x => x === value);
    this.getChartData(true, false, index);
  }

  public onChangeDataRangeRightChart(value: string) {
    let index = this.firstChartOptions.findIndex(x => x === value);
    this.getChartData(false, true, index);
  }
}
