import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Family } from 'src/app/models/family';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FamilyService {

  private _baseUrlService = environment.apiUrl + '/family/';

  constructor(private http: HttpClient) { }

  getFamilies() {
    return this.http.get<Family[]>(this._baseUrlService)
    .pipe(map(_families => {
        return _families;
    }));
  }

  postFamilies(userIds:number[], familyId:number = undefined, familyName:string = undefined) {
    let postObj = { userIds };
    if(familyId != undefined) {
      postObj['familyId'] = familyId;
    }
    if(familyName != undefined) {
      postObj['familyName'] = familyName;
    }    
    return this.http.post(this._baseUrlService, postObj)
    .pipe(map(_resp => {
        return _resp;
    }));
  }
}
